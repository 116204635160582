
<section class="about-area ptb-110">
    <div class="container">
        <div class="row align-items-center">
            
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <h2>Manufacturing</h2>
                    <p style="width: 90%;">Manufacturing is always about efficiency. No matter if you have a unique product or you are one of many: being efficient means staying in the game and being profitable. And being efficient means, among others:
                    </p>
                    <br/>
                    <div class="about-wrap-content">
                        <ul class="wrap-list">
                            <li><i class="fa-solid fa-check-double"></i> Having digital tendering and ordering solutions, to streamline the process and get the best price.
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Manage supply chain risks with proper supplier management tools.
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Control and utilize inventory with warehouse software. Be sure you have enough, but not overspend.
                            </li>
                        </ul>
                    </div>
                   
                    <br/>
                    <div class="row">
                        <div class="col-lg-5">
                        <span>&nbsp;</span>
                        </div>
                        <div class="col-lg-4" style="margin-top:30px; margin-bottom: 50px;">
                            <span >Want to see how it works?</span>
                        </div>
                        <div class="col-lg-3">
                            <a routerLink="/bookademo" class="btn btn-primary">BOOK A DEMO</a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>

