
<section class=" ptb-110">
    <div class="container">
        <div class="row ">
            
            <div class="col-lg-12 col-md-12">
                <div class="">
                    <h2>ViaCorex Privacy Policy</h2>
                    <div>
            <p>&nbsp;</p>
            <h2><span style="font-weight: 400;">Summary</span></h2>
            <p><span style="font-weight: 400;">Keeping your Personal Data 100% secure and giving you control over it is one of our main priorities at ViaCorex. Below is a summary of key points from our Privacy Policy, but we highly suggest you read the document in full.</span></p>
            <ul>
            <li style="font-weight: 400;"><span style="font-weight: 400;">Only those within your organization, with the appropriate access level granted by you, are able to view your information. This access is restricted on our end.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">If you’d like to access, amend or delete any information please contact </span><a href="mailto:support@viacorex.com"><span style="font-weight: 400;">support@viacorex.com</span></a><span style="font-weight: 400;">.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">If at any time you are concerned or have questions about how we handle your and/or your Users’ Personal Data, please reach out to our Data Protection Officer at </span><a href="mailto:support@viacorex.com"><span style="font-weight: 400;">support@viacorex.com</span></a><span style="font-weight: 400;">.</span></li>
            </ul>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">IF YOU DO NOT ACCEPT AND AGREE TO OUR PRIVACY POLICY THEN YOU MUST NOT ACCESS OR USE VIACOREX OR THE SERVICE.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">Who we are: </span><strong>UAB Amet Solutions</strong></p>
            <p><span style="font-weight: 400;">Company number: </span><strong>303100884</strong></p>
            <p><span style="font-weight: 400;">Registered office address:</span></p>
            <p><strong>Business Centre Trapecija (3rd floor), Pilaitės pr. 16, LT-04352 Vilnius, Lithuania</strong></p>
            <p><span style="font-weight: 400;">VAT ID: </span><strong>LT 100007923214</strong></p>
            <p>&nbsp;</p>
            <h2><span style="font-weight: 400;">Privacy Notice</span></h2>
            <p><span style="font-weight: 400;">ViaCorex (“ViaCorex”, “Service”, “We”, “Us” and “Our”) remain fully committed to the protection of your and your Users’ privacy at all times. The information contained in this privacy policy has been published to inform you of the way in which any Personal Data (as defined below) you provide us with or we collect from you will be used. Please read this information carefully in order to fully understand how we treat such Personal Data.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">Please read this policy in conjunction with the </span><span style="font-weight: 400;">Terms and Conditions</span><span style="font-weight: 400;"> for ViaCorex. When you access or use ViaCorex, you agree to our privacy policy and you consent to our collection, storage, use and disclosure of your and your Users’ Personal Data for the purpose of making ViaCorex and/or the Service available to you, in accordance with this policy.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">We will collect, store, use and disclose Personal Data in accordance with all applicable laws relating to the protection of Personal Data, including the EU Data Protection Directive 95/46/EC, the EU General Data Protection Regulation 2016/679, the EU ePrivacy Directive 2002/58/EC as amended by Directive 2009/136/EC, as amended or superseded from time to time, and any national implementing legislation (“Data Protection Laws”).</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">For the purpose of Data Protection Laws, in relation to any Personal Data you or any Users submit to our service, you will be the “Data Controller” and we will be a “Data processor” of such Personal Data.</span></p>
            <p>&nbsp;</p>
            <h2><span style="font-weight: 400;">Personal Data We May Obtain From You</span></h2>
            <p><span style="font-weight: 400;">“Personal Data” as defined by the GDPR </span><a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/key-definitions/"><span style="font-weight: 400;">here</span></a><span style="font-weight: 400;">.</span></p>
            <p><span style="font-weight: 400;">We may obtain and use the following Personal Data about you and anyone you chose to add to our service:</span></p>
            <ul>
            <li style="font-weight: 400;"><span style="font-weight: 400;">Any correspondence we have with you and your Users should you or your Users contact us. Data you or your Users provide when you fill out forms on our Website, including but not limited to data you provide when you register to become a user of the Service.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">Data and information you or your Users submit or upload to ViaCorex or the Service, including employee data (which may include, among other things, names, addresses, email addresses, telephone numbers and purchase details).</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">Data and information you or your Users submit or upload to ViaCorex or the Service, or data we collect from your systems or third-party services, including your customer data: (which may include among other things, names, email addresses, shipping/billing addresses, phone numbers, contact details); Sales data: (such as, details of the transactions undertaken through the Services, products/services purchased, date/time, payment amount/method, cancellations, returns, exchanges, communications with controller), Financial or payment information, Marketing preferences and communications.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">Any other data that consumers/end users have provided to the Customer which are processed through the Services, the extent of which is determined and controlled by the Customer or consumer/end-user in their sole discretion.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">Details of transactions made by you through the Website.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">Details of your or your Users’ visits to our Website, which includes without limitation location and traffic data, weblogs, resources you access and other communication data.</span></li>
            </ul>
            <p><span style="font-weight: 400;">You will be submitting and providing Client Data which may contain Personal Data (and potentially Sensitive Personal Data of Users and third parties. “Sensitive Personal Data” as defined by the GDPR </span><a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/key-definitions/"><span style="font-weight: 400;">here</span></a><span style="font-weight: 400;">.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">You represent and warrant that you i) have the right to transfer such Personal Data (including Sensitive Personal Data, if applicable) to us for the purpose of receiving the Service; and ii) are solely responsible for obtaining all required consents, authorisations and permissions from such Users and third parties and providing all required notifications to such Users and third parties (where applicable) to enable you to provide such information to us and to grant to us the rights set forth in this privacy policy and the </span><a href="https://www.viacorex.com/terms-and-conditions"><span style="font-weight: 400;">Terms &amp; Conditions </span></a><span style="font-weight: 400;">. It is your responsibility to ensure that all such Users and third parties are aware of and accept the terms of this policy and that you have obtained the explicit and informed consent of Users to our processing any of their Sensitive Personal Data in accordance with this policy and the </span><a href="https://www.viacorex.com/terms-and-conditions"><span style="font-weight: 400;">Terms &amp; Conditions </span></a><span style="font-weight: 400;">. You may not provide us with any Client Data or other information containing Personal Data of Users or third parties unless and until you have obtained all necessary consents, authorizations, and permissions to do so.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">You agree to indemnify us in accordance with Clause 18 of the </span><a href="https://www.viacorex.com/terms-and-conditions"><span style="font-weight: 400;">Terms &amp; Conditions </span></a><span style="font-weight: 400;">&nbsp;for any claims made by any Users arising from any unauthorized access or accidental loss, damage, corruption or disclosure of Client Data arising from or in connection with your and/ or our use or disclosure of Client Data.</span></p>
            <p>&nbsp;</p>
            <h2><span style="font-weight: 400;">Cookies and IP Addresses</span></h2>
            <p><span style="font-weight: 400;">“Cookies” are small data files that may be placed on the hard drive of your computer when you visit the ViaCorex site or a ViaCorex service. Cookies permit the ViaCorex to identify your browser whenever you interact with the ViaCorex site or a ViaCorex service.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">ViaCorex may obtain information about your and your Users’ computer, which includes your and your Users’ IP address, browser type and operating system where available. This accumulation of data is used to assist system administration.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">We may also collect information regarding your or your Users’ browsing activity and interests through the use of a cookie file. We use this data to help us improve the experience of users on our Website and Service, and to deliver a more personalized service with more relevant content. The collection of this data allows us to:</span></p>
            <ul>
            <li style="font-weight: 400;"><span style="font-weight: 400;">Store data indicative of your or your Users’ preferences, allowing us to adjust our Website and service to appeal to your individual interests; estimate the size and usage patterns of our audience;</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">Record the details of any transactions carried out by you through our Website and service;</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">Anonymously identify you or your Users upon your/their return to our Website and service; and/or</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">Increase the speed of your or your Users’ service.</span></li>
            </ul>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">We use the following cookies:</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">Strictly necessary cookies. These are cookies that are required for the operation of our Website and Service. They include, for example, cookies that enable you or your Users to log into secure areas of our Website or Service.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">Analytical/performance cookies. They allow us to recognize and count the number of visitors and to see how visitors move around our Website and Service when they are using it. This helps us to improve the way our Website and Service work.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">Functionality cookies. These are used to recognize you and your Users when you/they return to our Website. This enables us to personalize our content for you and your Users, greet you and your Users by name and remember your/their preferences.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">Targeting cookies. These cookies record your and your Users’ visit to our Website, the pages you and your Users have visited and the links you and your Users have followed. We will use this information to make our Website and the advertising displayed on it (if any) more relevant to your and your Users’ interests. We may also share this information with third parties for this purpose. In many cases, you can opt out of Advertising cookies via the ‘Your Online Choices’ website found at: </span><a href="http://www.youronlinechoices.com/uk/your-ad-choices"><span style="font-weight: 400;">Opt out</span></a></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">You can find more information about some of the individual cookies we use and the purposes for which we use them below. Example of cookies used by the Service:</span></p>
            <p>&nbsp;</p>
            
            <div id="tablepress-1_wrapper" class="dataTables_wrapper no-footer"><table id="tablepress-1" class="tablepress tablepress-id-1 dataTable no-footer" role="grid">
            <thead>
            <tr class="row-1 odd" role="row"><th class="column-1 sorting_disabled" rowspan="1" colspan="1" style="width: 122px;"><strong>Tool</strong></th><th class="column-2 sorting_disabled" rowspan="1" colspan="1" style="width: 1016px;"><strong>Cookie Purposes</strong></th></tr>
            </thead>
            <tbody>
            
            
            
            
            
            <tr class="row-2 even" role="row">
                <td class="column-1"><strong>Google Analytics</strong></td><td class="column-2">We use Google Analytics to collect information about how visitors use the Site. We use the information to compile reports and to help us improve the site. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from and the pages they visited. For more information about Google’s privacy policy, please visit: <a href="https://www.google.com/intl/en/policies/" rel="noopener noreferrer" target="_blank">Google Analytics</a><br>
            <br>
            If you do not wish to allow the use of Google Analytics cookies at all, Google provides an opt-out plug-in for most common website browsers: <a href="http://www.youronlinechoices.com/uk/your-ad-choices" rel="noopener noreferrer" target="_blank">Opt out</a></td>
            </tr><tr class="row-3 odd" role="row">
                <td class="column-1"><strong>Zoho Sales IQ</strong></td><td class="column-2">We use a Zoho Sales IQ system to manage our interactions and support with customers and prospective customers. Zoho Sales IQ system uses some Personal Data we obtain from you and your Users. For more information about the privacy policy of the system, please visit: <a href="https://www.zoho.com/privacy.html" rel="noopener noreferrer" target="_blank">Zoho Sales IQ</a></td>
            </tr><tr class="row-4 even" role="row">
                <td class="column-1"><strong>Facebook</strong></td><td class="column-2">We use Facebook cookies to understand the effectiveness of our Facebook advertising campaigns and to compare the performance of those campaigns to ads displayed on other websites and apps. For more information about Facebook’s privacy policy, please visit: <a href="https://www.facebook.com/policies/cookies/" rel="noopener noreferrer" target="_blank">Facebook</a><br>
            </td>
            </tr><tr class="row-5 odd" role="row">
                <td class="column-1"><strong>Google Tag Manager</strong></td><td class="column-2">We use Google Tag Manager to put the other cookies listed in this table on our website and services.</td>
            </tr><tr class="row-6 even" role="row">
                <td class="column-1"><strong>Google Ads</strong></td><td class="column-2">We use Google Ads cookies to understand the effectiveness of our Google Ads advertising campaigns and to compare the performance of those campaigns to ads displayed on other websites and apps.</td>
            </tr></tbody>
            </table></div>
            <!-- #tablepress-1 from cache -->
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;"><br>
            Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies. You and your Users remain entitled to refuse cookies by adjusting your browser settings accordingly. Doing so, however, may restrict your and your Users’ access to certain areas within our Website or Service. Unless you or your Users adjust your/their browser settings to refuse cookies, our system will issue cookies when you/they log on to our Website.</span></p>
            <h2><span style="font-weight: 400;">Where We Store Personal Data</span></h2>
            <p><span style="font-weight: 400;">The Personal Data we obtain from you, your Users and your systems (including, without limitation, Client Data) may be moved to and stored at a destination within the European Economic Area (“EEA”). Staff members operating within the EEA who work for or on behalf of us may process this information. Such staff members may, among other things, be involved in the provision of support services and the delivery of your and your Users’ request(s) for us to provide the Service.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">Without limiting the foregoing, you agree that Personal Data we obtain from you, your Users and your systems (including, without limitation, Client Data) may be processed by our service providers based in, countries outside of the EEA for the purposes of providing you with the Service. Such countries may not have laws offering the same level of protection for Personal Data as those inside the EEA; however where such transfers of data occur, we will take steps to prevent the transfer of Personal Data without adequate safeguards being put in place and will ensure that your and your Users’ Personal Data collected in the EEA and transferred internationally is afforded the same level of protection as it would be inside the EEA.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">We store the Personal Data you, your Users and your systems provide us with on our secure servers. In the event of us giving you or your Users (or you/they choosing) a password which grants you/them access to specific areas within our Website or Service, it remains your/their responsibility to maintain the confidentiality of this password. This includes the responsibility to refrain from sharing your/their password with other parties.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">As the transmission of data via the Internet cannot be assumed completely secure, we cannot guarantee the security of any of your or your Users’ data transmitted to our Website or Service; you are therefore responsible for any risk associated with such transmission. We will however at all times take all reasonable steps to ensure the transmission of your and your Users’ data is executed as securely as possible and upon receipt of your/their data we will continue at all times to enforce strict security procedures and features in an attempt to prevent any unauthorized access.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">You can find more information about some of the individual 3rd party sub processors we use and the purposes for which we use them below. Example of the 3rd party used by the Service:</span></p>
            <p>&nbsp;</p>
            
            <div id="tablepress-2_wrapper" class="dataTables_wrapper no-footer"><table id="tablepress-2" class="tablepress tablepress-id-2 dataTable no-footer" role="grid">
            <thead>
            <tr class="row-1 odd" role="row"><th class="column-1 sorting_disabled" rowspan="1" colspan="1" style="width: 116px;"><strong>Sub-processor</strong></th><th class="column-2 sorting_disabled" rowspan="1" colspan="1" style="width: 1022px;"><strong>Purpose</strong></th></tr>
            </thead>
            <tbody>
            
            <tr class="row-2 even" role="row">
                <td class="column-1"><strong>Microsoft Azure</strong></td><td class="column-2">We use Microsoft Azure to process and store the Personal Data we obtain from you, your Users and your systems (including, without limitation, Client Data). This data could be stored outside of the EEA in the US for one example. For more information about Microsoft Azure’s privacy policy, please visit: <a href="https://privacy.microsoft.com/en-us/PrivacyStatement">Microsoft Azure</a></td>
            </tr></tbody>
            </table></div>
            <!-- #tablepress-2 from cache -->
            <h2></h2>
            <h2><span style="font-weight: 400;">How We Protect Your Personal Data</span></h2>
            <p><span style="font-weight: 400;">We will take reasonable steps to maintain appropriate technical and organizational measures to protect the Personal Data you provide to us against accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to your Personal Data.</span></p>
            <p>&nbsp;</p>
            <h2><span style="font-weight: 400;">How We Use Your Personal Data</span></h2>
            <p><span style="font-weight: 400;">The Personal Data we hold about you and your Users may be used in any of the following ways:</span></p>
            <ul>
            <li style="font-weight: 400;"><span style="font-weight: 400;">To provide you and your Users with the Service, including administration and management of your account.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">To allow you to upload, store and access Client Data.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">To enable Users to access certain aspects of ViaCorex.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">To provide you and Users with user support.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">To moderate your account.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">For research and analytics purposes (for example, to improve the quality of the Service).</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">To ensure security for you, the Users, our staff and other users of the Service.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">To comply with applicable Laws, court orders, government and law enforcement agencies’ requests.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">To send you further information about our services for which we think you may have an interest.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">This information will be supplied only where you have given consent.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">To send you further information about our services based on a request we have received from you.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">To fulfill the obligations we have in relation to any contracts we have in place with you (including, without limitation, the </span><span style="font-weight: 400;">Terms &amp; Conditions</span><span style="font-weight: 400;"> ).</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">To provide you with notification about any changes to the Service.</span></li>
            </ul>
            <p>&nbsp;</p>
            <h2><span style="font-weight: 400;">Third Party Integration Partners</span></h2>
            <p><span style="font-weight: 400;">We will never supply your, your Users’ or your systems (including, without limitation, Client Data) Personal Data to third parties unless under the conditions stated beneath this section of our privacy policy. Notwithstanding the foregoing, you acknowledge and agree that we may process, aggregate and anonymize your, and your Users, Personal Data (excluding, for the avoidance of doubt, Sensitive Personal Data) and use and share such aggregated and anonymised Personal Data with third parties for statistical purposes and for the purpose of data analytics, product development, Service improvement, providing the service, and/or in order to fulfil the obligations we have in relation to any contracts we have in place with you (including, without limitation, the </span><span style="font-weight: 400;">Terms &amp; Conditions</span><span style="font-weight: 400;">). Such services are optional and by using the services you agree to these terms.</span></p>
            <p>&nbsp;</p>
            <h2><span style="font-weight: 400;">Disclosure Of Your Information</span></h2>
            <p><span style="font-weight: 400;">Disclosure of your and your Users’ Personal Data (including, without limitation, Client Data) to third parties will only occur in any of the following events:</span></p>
            <ul>
            <li style="font-weight: 400;"><span style="font-weight: 400;">We sell or purchase any business or assets. In such case, we may authorize the disclosure of your personal data to prospective sellers or buyers of such business or assets.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">All or the substantial majority of our assets are sold to a third party. In such case, your personal data may be one of the transferred assets.</span></li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">We are required to disclose your or your Users’ personal data in order to fulfill any legal obligation, to enforce our </span><span style="font-weight: 400;">Terms &amp; Conditions</span><span style="font-weight: 400;">, or to protect the property, rights or safety of ViaCorex, users of our services or others. In such case, information may be exchanged with third party companies or organizations in order to prevent fraud.</span></li>
            </ul>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">You acknowledge and agree that we may also disclose Personal Data (including, without limitation, Client Data) with: (i) our service providers involved in the provision, distribution, delivery and support of the Service, including the storage of any Client Data; (ii) fraud prevention agencies; (iii) law enforcement agencies, regulators, courts and public authorities; and (iv) emergency services.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">Our service providers have to follow our express instructions when processing the Personal Data you or your Users provide and must have in place appropriate technical and organizational security measures to safeguard such Personal Data, and we do not allow them to use this information for their own commercial purposes.</span></p>
            <p>&nbsp;</p>
            <h2><span style="font-weight: 400;">Your Rights</span></h2>
            <p><span style="font-weight: 400;">You retain the right to request us to refrain from processing your data for the purposes of marketing. To exercise such right, you may reply to any information we send you, detailing your request that we refrain from sending any marketing correspondence, or you can exercise this same right by contacting us electronically via email at </span><a href="mailto:support@viacorex.com"><span style="font-weight: 400;">support@viacorex.com</span></a><span style="font-weight: 400;">. If at any time we intend to use your data for such marketing purposes, however, it is standard practice for us to make you aware in advance of collecting such data.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">We may at times provide links on our Website to third party websites, including without limitation those owned or managed by our partner networks, affiliates or advertisers. These websites have separate privacy policies, and we, therefore, cannot accept any responsibility for the content. As such, choosing to follow these links is a choice you make at your own risk, and we advise that you check these websites’ individual privacy policies before submitting any personal data.</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">You retain at all times the right to access/amend/delete any Personal Data we hold about you or to exercise your right of data portability or to object to, or restrict, the purposes for which your Personal Data is processed on certain grounds. You may exercise this right by making a request in accordance with Data Protection Laws, by emailing </span><a href="mailto:support@viacorex.com"><span style="font-weight: 400;">support@viacorex.com</span></a><span style="font-weight: 400;">.</span></p>
            <p><span style="font-weight: 400;">You undertake to notify your Users of this privacy policy and of their rights under the Data Protection Law. We will provide you with reasonable assistance to enable you to comply and respond to a request, query or complaint from a User in relation to their Personal Data.</span></p>
            <p>&nbsp;</p>
            <h2><span style="font-weight: 400;">How Long We Keep Your Personal Data</span></h2>
            <p><span style="font-weight: 400;">We will keep your and your Users’ Personal Data for the duration of the Service, we may keep your and your Users’ Personal Data for a further 6 months past the end of the service to ensure a seamless return if you should decide to restart the service, in accordance with your instructions or for such periods as may be required by law.</span></p>
            <p><span style="font-weight: 400;">But you could just do the following if necessary: Personal data acquired with consent for marketing purposes will be kept for a maximum period of two years after a final interaction unless consent is rescinded or we have been instructed to delete the personal data by the natural persons.</span></p>
            <p>&nbsp;</p>
            <h2><span style="font-weight: 400;">Changes to Our Privacy Policy</span></h2>
            <p><span style="font-weight: 400;">If at any time we make a change to this policy, we will update this page to reflect such change. Where we feel it appropriate, we will notify you by email, however, we recommend you review this page periodically to ensure you remain happy with the latest version.</span></p>
            <p>&nbsp;</p>
            <h2><span style="font-weight: 400;">Questions, Comments and Getting in Touch</span></h2>
            <p><span style="font-weight: 400;">We welcome any questions or comments in relation to this privacy policy and advise you to send any such communication to </span><a href="mailto:support@viacorex.com"><span style="font-weight: 400;">support@viacorex.com</span></a><span style="font-weight: 400;">.</span></p>
            <p>&nbsp;</p>
                                        </div>
                    <br/>

                    
                </div>
            </div>
        </div>
    </div>
</section>

