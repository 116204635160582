<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Projects</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Projects</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Projects Area -->
<section class="projects-area ptb-110">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects1.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Design/Idea</span>
                        <h3><a routerLink="/project-details">Application Development</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/project-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects2.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Design/Development</span>
                        <h3><a routerLink="/project-details">Application Design</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/project-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects3.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Design</span>
                        <h3><a routerLink="/project-details">Web Application Development</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/project-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects4.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Idea</span>
                        <h3><a routerLink="/project-details">Big Data Analysis</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/project-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects5.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Guard</span>
                        <h3><a routerLink="/project-details">Cyber Security</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/project-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-projects-box">
                    <a routerLink="/project-details"><img src="assets/img/projects/projects6.jpg" alt="image"></a>

                    <div class="projects-content">
                        <span>Development</span>
                        <h3><a routerLink="/project-details">Network Marketing</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/project-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/projects" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                    <a routerLink="/projects" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/projects" class="page-numbers">3</a>
                    <a routerLink="/projects" class="page-numbers">4</a>
                    <a routerLink="/projects" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
        </div>

        <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
        <div class="shape15"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    </div>
</section>
<!-- End Projects Area -->