import { Component, OnInit } from '@angular/core';
export {}; declare global { interface Window { Calendly: any; } } 
@Component({
  selector: 'app-bookademo',
  templateUrl: './bookademo.component.html',
  styleUrls: ['./bookademo.component.scss']
})
export class BookademoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.Calendly.initInlineWidget({
      url: 'https://calendly.com/darius-viacorex/30min',
      parentElement: document.querySelector('.calendly-inline-widget'),
    });
  }

}
