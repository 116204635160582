<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-110">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog1.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 15, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 18, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">I Used The Web For A Day On A 50 MB Budget</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog3.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 15, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Here are the 5 most telling signs of micromanagement</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog4.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 15, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Add And Show Image With Size From Elementor Widget</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog5.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 18, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">How To The Active Menu Based On URL In Next.JS?</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog6.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 15, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Instagram Feed Add To Your WordPress Site</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/blog" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                    <a routerLink="/blog" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/blog" class="page-numbers">3</a>
                    <a routerLink="/blog" class="page-numbers">4</a>
                    <a routerLink="/blog" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section>
<!-- End Blog Area -->