<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/vcx_transp_logo.png" alt="image"></a>
                        <p>Efficient procurement and inventory management software for construction and manufacturing</p>
                    </div>

                    
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>

                    <ul class="quick-links-list">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/construction">Construction</a></li>
                        <li><a routerLink="/manufacturing">Manufacturing</a></li>
                        <li><a routerLink="/features">Features</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-contact-list">
                        <li><span>Address: </span> Astiku str. 7, Vilnius, Lithuania</li>
                        <li><span>Email: </span> <a href="mailto:support@viacorex.com">support@viacorex.com</a></li>
                        <li><span>Phone: </span> <a href="tel:+37062620400">+370 626 20 400</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>&copy; Copyright {{currentYear}} <a>Viacorex</a>. All Rights Reserved.</p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/privacy">Privacy Policy</a></li>
                        <li><a routerLink="/terms">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="circle-map"><img src="assets/img/circle-map.png" alt="image"></div>
</footer>

<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i></div>
