import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.scss'],
  animations: [
    trigger('appearFromAbove', [
      transition(':enter', [
        style({ transform: 'translateZ(-10%)', opacity: 0 }),
        animate('300ms ease-out', style({ transform: 'translateZ(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class IndustriesComponent implements OnInit {

  constructor() { }
  type: string = "general";
  ngOnInit() {
  }
  changetype(type: string) {
    this.type = type;
  }
}
