
<section class="features-style-area ptb-110" style="margin-top: 50px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12" style="margin-bottom: 30px;">
                <div class="features-style-card">
                    <h2>Integration with external BI tools
                    </h2>
                    <br/>
                    <div class="col-lg-6 col-md-6 col-sm-12"></div>
                    <div class="about-wrap-content">
                        <ul class="wrap-list">
                            <li><i class="fa-solid fa-check-double"></i> Data exchange via API
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Data exchange with external system via SQL database
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
                <div class="col-lg-6 col-md-6 col-sm-12" style="margin-bottom: 30px;">
                <div class="features-style-card">
                    <h2>Integration with accounting system
                    </h2>
                    <br/>
                    <div class="about-wrap-content">
                        <ul class="wrap-list">
                            <li><i class="fa-solid fa-check-double"></i> Integration with accounting system via API
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Exchange of supplier, purchase and warehouse data
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Possibility to add invoice to the specific order and automatically create a record in the accounting system
                            </li>
                           
                        </ul>
                    </div>
                </div>
                </div>
            </div>
    </div>
</section>
