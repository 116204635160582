
<section class="about-area ptb-110">
    <div class="container">
        <div class="row ">
            
            <div class="col-lg-12 col-md-12">
                <div class="">
                    <h2>ViaCorex Terms and Conditions</h2>
                    <div>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;" style="color:black">Summary of fundamental changes made inline with GDPR compliance</span></h2>
        <ul>
        <li style="font-weight: 400;"><span style="font-weight: 400;">ViaCorex has updated and published terms relating to our and customers use and handling of personal data processing, by the General Data Protection Regulation (GDPR), which comes into force on 25 May 2018.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">ViaCorex has updated and published our Privacy Policy, by the General Data Protection Regulation (GDPR), which comes into force on 25 May 2018.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">ViaCorex has clarified its (and customers) relationship with Third-Party Integration Partners whose services integrate with ViaCorex.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">ViaCorex customers are increasingly working with ViaCorex partners and other service providers, and so clarity has been added about how customers may authorize those third parties and other authorized users to access and use the ViaCorex services on behalf of customers.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">We have explained the instances where it may be necessary for your account to be removed or suspended, and how and when your data is removed following the removal of your ViaCorex account.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">We’ve included more detail on the limits and restrictions that apply to the range of ViaCorex plans available to you.</span></li>
        </ul>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">1. Our Service</span></h2>
        <p><span style="font-weight: 400;">ViaCorex is a software application provided by Amet Solutions Ltd (“ViaCorex”, “service”, “us”, “our” or ‘we’) to customers (‘you’ and ‘your’).</span></p>
        <p><span style="font-weight: 400;">Use of this Service and additional services introduced by Amet Solutions Ltd and contained within the ViaCorex application constitutes acceptance by you of the Terms &amp; Conditions.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">2. Eligibility</span></h2>
        <p><span style="font-weight: 400;">You must be a minimum age of 18 to register on and use the Service. By registering and using the Service you warrant that you are 18 or older and understand your obligations under these Terms &amp; Conditions.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">3. Registration and Account Integrity</span></h2>
        <p><span style="font-weight: 400;">3.1 As part of the registration process, you will need to create an account, including an email &amp; password. It is your responsibility to ensure that the information you provide is accurate, not misleading and relates to you. You cannot create an account or email &amp; password using the names and information of another person or use words that are the trademarks or the property of another party (including ours), or vulgar, obscene or in any other way inappropriate. We reserve the right with or without notice to suspend or terminate any account in breach.</span></p>
        <p><span style="font-weight: 400;">3.2 Where we provide an organization with many user licenses for the Service these must be adhered to. User licenses cannot be shared between users. A breach of this clause will result in termination of the abused accounts and/or all accounts provided to the organization.</span></p>
        <p><span style="font-weight: 400;">3.3 If for any reason you suspect that your email &amp; password has been disclosed to or obtained by another party you should contact us immediately. Please note that we never contact users requesting them to confirm their email &amp; password or other details.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">4. Pricing, Plans, and Features</span></h2>
        <p><span style="font-weight: 400;">4.1 For current pricing and plans please see the pricing page located on our website </span><a href="https://viacorex.com/#pricing"><span style="font-weight: 400;">https://viacorex.com</span></a></p>
        <p>or you can contact us about pricing via email <a href="mailto:support@viacorex.com">support@viacorex.com&nbsp;</a></p>
        <p><span style="font-weight: 400;">4.2 We reserve the right to change pricing, plans and the features offered at any time and without notice to you.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">5. Payment and Credit Control</span></h2>
        <p><span style="font-weight: 400;">5.1 All services are billed monthly in advance.</span></p>
        <p><span style="font-weight: 400;">5.2 Where the Service has been paid for 12 months in advance, (where we offer this option) payment will be taken in full on each anniversary (unless the account holder has requested to cancel before this date).</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">6. Cancellation</span></h2>
        <p><span style="font-weight: 400;">6.1 Cancellation should be made by completing the cancellation form*, which can be obtained by sending an email to support@viacorex.com</span></p>
        <p><span style="font-weight: 400;">*Please note that the cancellation process will not be triggered until we have received a completed cancellation request.</span></p>
        <p><span style="font-weight: 400;">6.2 Cancellation by any other means, including (but not limited to) telephone call, fax, text or instant message is not valid.</span></p>
        <p><span style="font-weight: 400;">6.3 No refunds will be provided for remaining unused days under a monthly account or annual accounts.</span></p>
        <p><span style="font-weight: 400;">6.4 For 12-month accounts (called annual) canceled within the first 12 months will need to continue paying until the 12 months has expired. No refund will be offered for days remaining in the month of cancellation.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">7. Upgrading or Downgrading Accounts</span></h2>
        <p><span style="font-weight: 400;">7.1 Users can upgrade (increase users for your account) or downgrade (decrease users for your account) their accounts and the services offered at any time.</span></p>
        <p><span style="font-weight: 400;">7.2 No refunds will be provided for downgraded monthly accounts that have been downgraded before the monthly charge is due on the account. The new lower monthly fee will be automatically applied to the usual date of the monthly fee.</span></p>
        <p><span style="font-weight: 400;">7.3 Where an account is upgraded the new higher monthly charge will be applied to the next usual date of the monthly cost.</span></p>
        <p><span style="font-weight: 400;">7.4 In the case 12-month accounts (where offered) no refund will be provided for all complete remaining months of the service where the user downgrades the account. No refund will be provided for days remaining in the month of the downgrade.</span></p>
        <p><span style="font-weight: 400;">7.5 Where a 12-month account is upgraded the increased charge will be applied immediately to the account for the additional amount due until the anniversary date.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">8. Trial and Offer Periods</span></h2>
        <p><span style="font-weight: 400;">8.1 Trial or offer periods are offered at our sole discretion and are subject to withdrawal at any time and without notice.</span></p>
        <p><span style="font-weight: 400;">8.2 These Terms and Conditions apply to all trial or offer period accounts.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">9. Technical Support</span></h2>
        <p><span style="font-weight: 400;">9.1 Technical support is provided via email and such other means as we decide to give a proper service to users.</span></p>
        <p><span style="font-weight: 400;">9.2 We reserve the right not to provide a technical support service to free or trial account users or any user if we deem the request unreasonable.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">10. Specific Service Rules</span></h2>
        <p><span style="font-weight: 400;">10.1 As a user you agree not to do any of the following but not limited too:</span></p>
        <p><span style="font-weight: 400;">Abuse, harass, threaten, stalk, defame or in anyway seek to violate the rights of another user or third party.</span></p>
        <p>&nbsp;</p>
        <ul>
        <li style="font-weight: 400;"><span style="font-weight: 400;">Publish or seek to distribute any material or information that is unlawful, harmful, obscene, indecent, libelous, profane, defamatory, racist, or in any other way inappropriate or objectionable.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">Use or harvest data provided by other users in a way that they would object to.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">Encourage illegal activity or activity that violates the rights of other Service users or third parties, whether individuals or organizations.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">Supply or post content calculated to deliberately mislead other users or third parties, including content falsely made to appear from or be endorsed by us.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">To pose as another user, third party or organization employee to obtain user or third party information.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">To transmit or transfer any viruses, trojans, worms or any other malicious programs or code intended to spy on, gain control over, disrupt, destroy or in any other way impair any computer hardware or software or any other equipment.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">Attempt to gain access to our servers or other equipment to disrupt, impair, overload or otherwise hinder or compromise the safety, security or privacy of any of the services provided by or relied upon by users and us.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">Reframe or repurpose the Service or any content on it, remove, obscure or block any notices (and advertising as applicable) provided by us on the Service.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">Load or provide access to content on the Service or link to other content from the Service, which infringes the trademark, patent, trade secret or any other proprietary right of a third party or infringes any intellectual property law.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">Send junk or spam email or emails or posts promoting pyramid schemes, chain letters or any other activity that invites users and others to participate in wasting their time and/or money.</span></li>
        <li style="font-weight: 400;"><span style="font-weight: 400;">Use any robot, spider, scraper or other technical means to access the Service or any content on the Service.</span></li>
        </ul>
        <p><span style="font-weight: 400;">10.2 A breach of these Terms &amp; Conditions by sending any unsolicited bulk email, (spam) or any other bulk communications will cause harm to the Service and to us. Such damage is difficult to quantify, and as such you agree to pay us the sum of 50€ for each and every individual email or other communication sent to a user or third party.</span></p>
        <p><span style="font-weight: 400;">10.3 The above list (10.1) is not intended to be exhaustive. We reserve the right to remove (without notice) any content and suspend or terminate (without notice) the account of any user who in our sole judgment is in breach of these Terms and Conditions.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">11. Content Ownership</span></h2>
        <p><span style="font-weight: 400;">11.1 As a user, you retain all ownership rights to content provided by you.</span></p>
        <p><span style="font-weight: 400;">11.2 You warrant that any content provided by you does not belong to a third party whose rights have been violated by the content being posted on to the Service. Furthermore, if a third party owns any content, you agree to pay all royalties, fines, and settlements owed to that party, without seeking any contribution from us.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">12. Copyrighted Material</span></h2>
        <p><span style="font-weight: 400;">12.1 We do not condone or encourage in any way the posting of copyrighted or proprietary content or information by any users who are not the legal owners of such content.</span></p>
        <p><span style="font-weight: 400;">12.2 Where notified of such breaches by the owner of such content we will remove the content from the Service as soon as practicable. But only where we can reasonably ascertain the actual owner of such content.</span></p>
        <p><span style="font-weight: 400;">12.3 If as the owner of such content you believe that your rights have been infringed you should contact us as soon as possible and provide all relevant information in writing.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">13. Content Monitoring</span></h2>
        <p><span style="font-weight: 400;">Users can freely add content to the Service. We do not monitor or assume any responsibility for content posted onto the Service. If at any time we decide to track the Service on any occasion it does not mean that we assume responsibility for removing any content or the conduct of any users at the time or in the future.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">14. Termination</span></h2>
        <p><span style="font-weight: 400;">14.1 We may terminate your user account and all content and materials associated with it at any time where these Terms &amp; Conditions have been breached. Such termination can be without notice. As a user, you can choose to terminate your account at any time (subject to any payment plan entered into) and are free to remove any content you have created on termination.</span></p>
        <p><span style="font-weight: 400;">14.2 Various clauses within these Terms &amp; Conditions are designed to survive and continue after termination, including (but not limited to) clauses 11 and 18.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">15. Access and Backups</span></h2>
        <p><span style="font-weight: 400;">15.1 We take all reasonable steps to ensure that the Service is available and functioning fully at all times. However, we do not accept any responsibility for “downtime” or poor performance of our servers or infrastructure, or where the Service is unavailable for any other reason, whether within or outside our direct control.</span></p>
        <p><span style="font-weight: 400;">15.2 You are solely responsible for backing up any content or data entered into the Service by you. We strongly recommend that you wholly and regularly backup all of your content and data on the Service.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">16. Disclaimer</span></h2>
        <p><span style="font-weight: 400;">16.1 We are not responsible for the accuracy of any content on the Service, nor any advertisements placed on the Service.</span></p>
        <p><span style="font-weight: 400;">16.2 We are not responsible for any links to third party websites from the Service and the inclusion of any link does not imply an endorsement of a third party website or service by us.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">17. Limitation of Liability</span></h2>
        <p><span style="font-weight: 400;">We shall not be liable for any indirect, consequential, exemplary, incidental, special or punitive damages, including loss of profits.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">18. Indemnity</span></h2>
        <p><span style="font-weight: 400;">You agree to indemnify and hold our subsidiaries and us, affiliates and partners and their respective officers and employees harmless from any loss, fines, fees, liability or claim made by any third party arising from your breach of these Terms &amp; Conditions while using the Service or any other service provided by us.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">19. Privacy</span></h2>
        <p><span style="font-weight: 400;">Use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms &amp; Conditions by this reference.</span><span style="font-weight: 400;"> Privacy Policy (https://viacorex.com/privacy-policy/)</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">20. Severability</span></h2>
        <p><span style="font-weight: 400;">The previous paragraphs, subparagraphs, and clauses of these Terms &amp; Conditions shall be read and construed independently of each other. Should any part of this agreement or its paragraphs, sub-paragraphs or clauses be found invalid, it shall not affect the remaining paragraphs, subparagraphs, and clauses.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">21. Waiver</span></h2>
        <p><span style="font-weight: 400;">Failure by us to enforce any accrued rights under these Terms &amp; Conditions is not to be taken as or deemed to be a waiver of those rights unless we acknowledge the waiver in writing.</span></p>
        <p>&nbsp;</p>
        <h2><span style="font-weight: 400;">22. Use of third-party services</span></h2>
        <p><span style="font-weight: 400;">22.1 As part of the implementation service, we may recommend the use of third parties for specific development or integrations required by you.</span></p>
        <p><span style="font-weight: 400;">22.2 We are not responsible for any issues or delays with any third-party technology, information and/or services and will not be liable for those issues.</span></p>
        <p><span style="font-weight: 400;">22.3 You are entitled to introduce to us another third-party development agency who you already have an existing relationship.</span></p>
                                    </div>
                    <br/>

                    
                </div>
            </div>
        </div>
    </div>
</section>

