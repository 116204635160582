<section class="projects-wrap-area ptb-110" style="padding-bottom:0px">
    <div class="container-fluid">
        <div class="row align-items-center ">
            <div class="col-lg-3 col-md-4">
                <div class="single-projects-card">
                    <a ><img src="assets/img/Construction.jpg" alt="image"></a>
                    <div class="projects-content">
                        <h3>General construction</h3>
                        <p></p>
                        <a href="javascript:void(0);" (click)="changetype('general');" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="single-projects-card">
                    <a><img src="assets/img/Shipbuilding.jpg" alt="image"></a>

                    <div class="projects-content">
                        <h3>Shipbuilding</h3>
                        <p></p>
                        <a href="javascript:void(0);" (click)="changetype('ship');" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="single-projects-card">
                    <a><img src="assets/img/Electricity.jpg" alt="image"></a>

                    <div class="projects-content">
                        <h3>Specialty contractors</h3>
                        <p></p>
                        <a href="javascript:void(0);"  (click)="changetype('special')" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<div class="about-area-container">
    <!-- general -->
    <section class="about-area" *ngIf="type =='general'" @appearFromAbove id="general-construction" class="projects-wrap-area ptb-110" style="padding-bottom:0px" #generalConstruction>
        <div class="container"  >
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <div class="about-content">
                        <h2 >General construction</h2>
                        <p style="width: 90%;">Construction business is definitely one of the most challenging and dynamic. There are hundreds of things to manage with each project and hundreds of  things, that can go wrong. Therefore, it is crucial for managers to have tools for accessing real-time data and really control daily business activities.</p>
                        <br/>
                        <div class="about-wrap-content">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Getting the best price for materials and subcontracting works
                                </li>
                                <li><i class="fa-solid fa-check-double"></i> Having real-time expense visibility for efficient budget control
                                </li>
                                <li><i class="fa-solid fa-check-double"></i> Purchasing process you can actually control
                                </li>
                            </ul>
                        </div>
                       
                       
     <br/>
                        <div class="row">
                            <div class="col-lg-5">
                            <span>&nbsp;</span>
                            </div>
                            <div class="col-lg-4" style="margin-top:30px; margin-bottom: 50px;">
                                <span >Want to see how it works?</span>
                            </div>
                            <div class="col-lg-3">
                                <a routerLink="/bookademo" class="btn btn-primary">BOOK A DEMO</a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ship -->
    <section class="about-area" *ngIf="type =='ship'" @appearFromAbove id="shipbuilding" class="projects-wrap-area ptb-110" style="padding-bottom:0px" #shipbuildingSection>
        <div class="container" >
            <div class="row align-items-center">
                
                <div class="col-lg-12 col-md-12">
                    <div class="about-content">
                        <h2>Shipbuilding</h2>
                        <p style="width: 90%;">Building or repairing the ship is a whole different kind of construction: deadlines are really tight, and teams often work in different locations. With just a few processes improved, projects can be managed way more efficiently.
                        </p>
                        <br/>
                        <div class="about-wrap-content">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Smooth and precise purchasing procedure to avoid errors and delays
                                </li>
                                <li><i class="fa-solid fa-check-double"></i> Efficient warehouses with real-time visibility of inventory  and its movement
                                </li>
                                <li><i class="fa-solid fa-check-double"></i> Efficient supplier management tools to assure on-time delivery
                                </li>
                            </ul>
                        </div>
                       
                       
                        <br/>
                        <div class="row">
                            <div class="col-lg-5">
                            <span>&nbsp;</span>
                            </div>
                            <div class="col-lg-4" style="margin-top:30px; margin-bottom: 50px;">
                                <span >Want to see how it works?</span>
                            </div>
                            <div class="col-lg-3">
                                <a routerLink="/bookademo" class="btn btn-primary">BOOK A DEMO</a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- special -->
    <section class="about-area" *ngIf="type =='special'" [@appearFromAbove] id="Specialty contractors" class="projects-wrap-area ptb-110" style="padding-bottom:0px" #specialtyContractors>
        <div class="container" >
            <div class="row align-items-center">
                
                <div class="col-lg-12 col-md-12">
                    <div class="about-content">
                        <h2>Specialty contractors</h2>
                        <p style="width: 90%;">Specialty contractors, doing electricity, HVAC, scaffolding, plumbing and welding, or thermal insulation works, face a range of challenges in the construction industry. Life becomes significantly easier when movement of tools and materials  is taken into control.
                        </p>
                        <br/>
                        <div class="about-wrap-content">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Efficient tools and safety equipment tracking</li>
                                <li><i class="fa-solid fa-check-double"></i> Real-time visibility  of warehouse inventory  and its movement</li>
                                <li><i class="fa-solid fa-check-double"></i> Efficient purchase request and order handling</li>
                            </ul>
                        </div>
                       
                       
                                                <br/>
                        <div class="row">
                            <div class="col-lg-5">
                            <span>&nbsp;</span>
                            </div>
                            <div class="col-lg-4" style="margin-top:30px; margin-bottom: 50px;">
                                <span >Want to see how it works?</span>
                            </div>
                            <div class="col-lg-3">
                                <a routerLink="/bookademo" class="btn btn-primary">BOOK A DEMO</a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


