<!-- Start Navbar Area -->
<div class="navbar-area" [ngClass]="{'bg-white': router?.url === '/index-2' || router?.url === '/'}">
    <div class="{{'crimo-nav navbar-style-two'}}">
        <div class="{{'container'}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img style="max-height: 50px;" src="assets/img/LOGO_VCX.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item" (click)="collapseNavbar()">
                            <a routerLink="/" routerLinkActive="active" class="nav-link">Home</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a routerLink="/features" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" (click)="collapseNavbar()">Features <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" (click)="collapseNavbar()">
                                    <a routerLink="/procurement" routerLinkActive="active" class="nav-link">Procurement</a>
                                </li>
                                <li class="nav-item" (click)="collapseNavbar()">
                                    <a routerLink="/asset" routerLinkActive="active" class="nav-link">Inventory management</a>
                                </li>
                                <li class="nav-item" (click)="collapseNavbar()">
                                    <a routerLink="/budget" routerLinkActive="active" class="nav-link">Budget control and data analytics</a>
                                </li>
                                <li class="nav-item" (click)="collapseNavbar()">
                                    <a routerLink="/api" routerLinkActive="active" class="nav-link">API and integrations</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a routerLink="/industries" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" (click)="collapseNavbar()">Industries <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" (click)="collapseNavbar()">
                                    <a routerLink="/construction" routerLinkActive="active" class="nav-link">Construction</a>
                                </li>
                                <li class="nav-item" (click)="collapseNavbar()">
                                    <a routerLink="/manufacturing" routerLinkActive="active" class="nav-link">Manufacturing</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item" (click)="collapseNavbar()">
                            <a routerLink="/about" routerLinkActive="active" class="nav-link">About</a>
                        </li>
                        <li class="nav-item " (click)="collapseNavbar()" *ngIf="isMobileView">
                            <a routerLink="/bookademo" routerLinkActive="active" class="nav-link">Book a demo</a>
                        </li>
                        <li class="nav-item" (click)="collapseNavbar()" *ngIf="isMobileView">
                            <a routerLink="/bookademo" routerLinkActive="active" class="nav-link"><span class="fusion-megamenu-icon"><i style="color: #e34b1a;" class="glyphicon fa-arrow-right fas"></i></span><span class="menu-text" style="font-size: 17px;
                                font-weight: 600;
                                
                                color: #e34b1a;">Login</span></a>
                        </li>
                    </ul>
                    <div class="others-options" (click)="collapseNavbar()">
                        <a routerLink="/bookademo" class="btn btn-primary">BOOK A DEMO</a>
                    </div>
                    <div class="others-options nav-item">

                        <a href="https://portal.viacorex.com/#/login?culture=en-GB" class="nav-link"><span class="fusion-megamenu-icon"><i style="color: #e34b1a;" class="glyphicon fa-arrow-right fas"></i></span><span class="menu-text" style="font-size: 17px;
                            font-weight: 600;
                            
                            color: #e34b1a;">Login</span></a>
                    </div>
                    
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
