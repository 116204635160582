<!-- Start IT Solutions Slides Area -->
<div class="it-solutions-slides owl-carousel owl-theme">
    <div class="it-solutions-item">
        <div class="container">
            <div class="it-solutions-banner-content">
                <h1 style="font-size: 60px;">Efficient procurement and inventory management software for construction and contract manufacturing</h1>
                <div class="row it-solutions-banner-content align-items-center">
                    <div class="col-6 col-lg-8 col-md-8 col-sm-6 d-flex align-items-center">
                        <h3 style="margin: 0;"> <!-- Added inline style to remove margin -->
                            <a routerLink="/industries" class="industry-link" style="text-decoration: underline; color: #2EC7C8; cursor: pointer;">Find out how it works for your industry</a>
                        </h3>
                    </div>
                    <div class="btn-box col-4 col-lg-4 col-md-4 col-sm-6 d-flex align-items-center">
                        <a routerLink="/bookademo" class="btn btn-primary">Book a demo</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End IT Solutions Slides Area -->

<div class="container">
    <div class="funfacts-style-wrap-inner-box ptb-110">
    <div class="row justify-content-center">
        <div class="col-8 col-lg-4 col-md-4 col-sm-6">
            <div class="single-funfacts">
                <div class="circlechart" data-percentage="15"></div>
                <span>Saved</span>
                <h3>Expences</h3>
            </div>
        </div>

        <div class="col-8 col-lg-4 col-md-4 col-sm-6">
            <div class="single-funfacts">
                <div class="circlechart" data-percentage="50"></div>
                <span>Increased</span>
                <h3>Efficiency</h3>
            </div>
        </div>

        <div class="col-8 col-lg-4 col-md-4 col-sm-6">
            <div class="single-funfacts">
                <div class="circlechart" data-percentage="100"></div>
                <span>Unquestionable</span>
                <h3>Transparency</h3>
            </div>
        </div>
    </div>    
</div>
<!-- Start Features Style Area -->
<div class="features-style-area pt-110 pb-80">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="features-style-card text-start">
                    <div class="features-content" >
                        <div style="min-height: 150px">
                            <h3>Procurement</h3>
                            <p>Easily control your purchases and take the most out of the global market and multiple suppliers. We will provide you with tools for that.</p>
                        </div>
                        <a routerLink="/procurement" class="features-btn">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-style-card text-start">
                     <div class="features-content">
                        <div style="min-height: 150px">
                            <h3>Inventory management</h3>
                            <p>Efficient and real time visible inventory management saves money and improves utilization.</p>
                        </div>
                        <a routerLink="/asset" class="features-btn">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-style-card text-start">
                     <div class="features-content">
                        <div style="min-height: 150px">
                            <h3>Budget control and data analytics</h3>
                            <p>Our dashboards and real-time reports empowers data-driven decision making</p>
                        </div>
                        <a routerLink="/budget" class="features-btn">Read More</a>
                    </div>
                </div>
             </div>
             <div class="col-lg-3 col-md-6">
                <div class="features-style-card text-start">
                    <div class="features-content">
                        <div style="min-height: 150px">
                            <h3>API and integrations</h3>
                            <p>Exchange real-time data with external software, like your accounting system.</p>
                        </div>
                        <a routerLink="/api" class="features-btn">Read More</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- End Features Style Area -->

<!-- Start Partner Area -->
<div class="partner-area">
    <div class="container">
        
        <div class="row"></div>
        <div class="partner-list">
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/AVS_logo_BW.png" alt="image"></a>
             </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/RNDV.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/Peikko_BW.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/PST_BW.png" alt="image"></a>
        </div>
    </div>
</div>
<!-- End Partner Area -->
<br>

<!-- Start Services Wrap Area -->
    <div class="container">
        <div class="section-title-style-two text-center">
            <div class="content">
                
            <h2>ViaCorex tools for efficient business management</h2>
            </div>

        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span></span>
                    <h3>
                        <a href="features#Purchase">Purchase requests and orders
                        </a>
                    </h3>
                    <p>Easily control who is ordering what.</p>
                    <a href="features#Purchase" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span></span>
                    <h3>
                        <a href="features#Tenders">Tendering</a>
                    </h3>
                    <p>Be sure to get the best offer from your suppliers.</p>
                    <a href="features#Tenders" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span></span>
                    <h3>
                        <a href="features#Approval">Approval procedures</a>
                    </h3>
                    <p>Involve and get approvals from all stakeholders.</p>
                    <a href="features#Approval" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span></span>
                    <h3>
                        <a href="features#supplier-management">Supplier management</a>
                    </h3>
                    <p>All necessary information about your suppliers in one place.</p>
                    <a href="features#supplier-management" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span></span>
                    <h3>
                        <a href="features#purchase-catalogue">Purchase catalogue</a>
                    </h3>
                    <p>Enhance communication and speed up work with standartized catalogue.</p>
                    <a href="features#purchase-catalogue" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span></span>
                    <h3>
                        <a href="features#warehouse-inventory-management">Warehouse management </a>
                    </h3>
                    <p>Simple and powerful tool to manage your stock and its movement.</p>
                    <a href="features#warehouse-inventory-management" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span></span>
                    <h3>
                        <a href="features#tools">Tools and safety equipment management</a>
                    </h3>
                    <p>Manage all tools and safety equipment. Assign them to employees, keep track on waranty and certificate expiration.</p>
                    <a href="features#tools" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span></span>
                    <h3>
                        <a href="features#Reports">Real-time reports and dashboards</a>
                    </h3>
                    <p>Make data-based desicions with real-time information about your expences and inventory.</p>
                
                    <a href="features#Reports" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-services-wrap-card">
                    <span></span>
                    <h3>
                        <a routerLink="/api">Integrations and data exchange</a>
                    </h3>
                    <p>Securely exchange data with other systems, avoid unnecessary data re-entry and use your BI tools to the full.</p>
                    <a routerLink="/api" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                </div>
            </div>
        </div>

</div>
<!-- End Services Wrap Area -->





