
<section class="features-style-area ptb-110" style="margin-top: 50px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12" style="margin-bottom: 30px;">
                <div class="features-style-card">
                    <h2>Warehouse inventory management
                    </h2>
                    <br/>
                    <div class="col-lg-6 col-md-6 col-sm-12"></div>
                    <div class="about-wrap-content">
                        <ul class="wrap-list">
                            <li><i class="fa-solid fa-check-double"></i> Unlimited number of warehouses
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Stock movement among warehouses
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Stock write-off and take-off
                            </li>
                              <li><i class="fa-solid fa-check-double"></i> All related documents
                            </li>
                       </ul>
                        
                    </div>
                </div>
            </div>
                <div class="col-lg-6 col-md-6 col-sm-12" style="margin-bottom: 30px;">
                <div class="features-style-card">
                    <h2>Tools and equipment management
                    </h2>
                    <br/>
                    <div class="about-wrap-content">
                        <ul class="wrap-list">
                            <li><i class="fa-solid fa-check-double"></i> Automatic assignment of inventory number
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Serial no., production and expiry date tracking
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Inventory and tools assignment to employees 
                            </li>
                           
                        </ul>
                    </div>
                </div>
                </div>
            </div>
    </div>
</section>

