import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { BookademoComponent } from './components/pages/bookademo/bookademo.component';
import { ManufacturingComponent } from './components/pages/manufacturing/manufacturing.component';
import { ConstructionComponent } from './components/pages/construction/construction.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { ApiComponent } from './components/pages/api/api.component';
import { ProcurementComponent } from './components/pages/procurement/procurement.component';
import { AssetComponent } from './components/pages/asset/asset.component';
import { BudgetComponent } from './components/pages/budget/budget.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { IndustriesComponent } from './components/pages/industries/industries.component';

const routes: Routes = [
    { path: '', component: HomeFourComponent, title: 'ViaCorex -Advanced Procurement & Asset Management - Home' } ,
    { path: 'about', component: AboutComponent, title: 'ViaCorex -Advanced Procurement & Asset Management - About' } ,
    { path: 'team', component: TeamComponent, title: 'ViaCorex -Advanced Procurement & Asset Management - Team' } ,
    // { path: 'services', component: ServicesComponent },
    // { path: 'service-details', component: ServiceDetailsComponent },
    // { path: 'projects', component: ProjectsComponent },
    // { path: 'project-details', component: ProjectDetailsComponent },
    { path: 'coming-soon', component: ComingSoonComponent, title: 'ViaCorex -Advanced Procurement & Asset Management - Coming soon' } ,
    // { path: 'faq', component: FaqComponent },
    // { path: 'blog', component: BlogComponent },
    // { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'contact', component: ContactComponent, title: 'ViaCorex -Advanced Procurement & Asset Management - Contact' } ,
    { path: 'bookademo', component: BookademoComponent, title: 'ViaCorex -Advanced Procurement & Asset Management - Book a demo' } ,
    { path: 'manufacturing', component: ManufacturingComponent,  title: 'ViaCorex -Advanced Procurement & Asset Management - Manufacturing' },
    { path: 'construction', component: ConstructionComponent,  title: 'ViaCorex -Advanced Procurement & Asset Management - Construction' },
    { path: 'privacy', component: PrivacyComponent,  title: 'ViaCorex -Advanced Procurement & Asset Management - Privacy' },
    { path: 'terms', component: TermsComponent,  title: 'ViaCorex -Advanced Procurement & Asset Management - Terms' },
    { path: 'api', component: ApiComponent,  title: 'ViaCorex -Advanced Procurement & Asset Management - Api' },
    { path: 'procurement', component: ProcurementComponent,  title: 'ViaCorex -Advanced Procurement & Asset Management - Procurement' },
    { path: 'asset', component: AssetComponent,  title: 'ViaCorex -Advanced Procurement & Asset Management - Assets' },
    { path: 'budget', component: BudgetComponent,  title: 'ViaCorex -Advanced Procurement & Asset Management - Budgets' },
    { path: 'features', component: FeaturesComponent,  title: 'ViaCorex -Advanced Procurement & Asset Management - Features' },
    { path: 'industries', component: IndustriesComponent,  title: 'ViaCorex -Advanced Procurement & Asset Management - Industries' },

    // Here add new pages component

    { path: '**', component: NotFoundComponent } // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
