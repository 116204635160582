
<!-- Start Purchases requests and orders -->
<div class="about-wrap-area pt-110 pb-110">
        <div class="container">
            <div class="row align-items-center" style="margin-bottom: 70px;"> 
                <span id="Purchase">
                    <h1>Features to boost efficiency and increase profitability</h1>
                </span>
            </div>
        </div>
        <div class="container">
                <div class="row align-items-center" style="margin-bottom: 30px;margin-top: 30px">  
            <div class="col-lg-4 col-md-12">
                <div class="about-wrap-image">
                    <img src="assets/img/Purchase1.png" style="max-width: 80%;">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-wrap-content">
              <h2>Purchase requests and orders</h2>
                    <p>Forget scattered emails and phone calls to make purchase request for procurement department or order from supplier. All communication is in one place, with full history</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Real time statuses off purchase orders and requests</li>
                                <li><i class="fa-solid fa-check-double"></i> Internal messaging and document exchange</li>  
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Full/partial delivery control</li>
                                <li><i class="fa-solid fa-check-double"></i> Customizable user rights and access</li>
                            </ul>
                        </div>
                    </div>
                    <span id="Tenders"></span>
                    <div class="row">

                        <div class="col-lg-6" style="margin-top:20px">
                            <span id="Tenders" >Want to see how it works?</span>
                        </div>
                        <div class="col-lg-4">
                            <a routerLink="/bookademo" class="btn btn-primary" style="margin-top:10px">BOOK A DEMO</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
<!-- End Purchases requests and orders -->
<!-- Start tenders-->
        <div class="row align-items-center" style="margin-bottom: 30px;margin-top: 30px">
            <div class="col-lg-4 col-md-12">
                <div class="about-wrap-image">
                    <img src="assets/img/Tender2.png" style="max-width: 70%;">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-wrap-content">
                    <h2 >Tenders</h2>
                    <p>Take the most out of the global market and multiple suppliers. We will provide you with a simple tool to get the best price.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Request prices or informations</li>
                                <li><i class="fa-solid fa-check-double"></i> All bids in single, comparable table</li>
                                <li><i class="fa-solid fa-check-double"></i> Messaging with supliers and coleagues</li>  
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Multiple stages</li>
                                <li><i class="fa-solid fa-check-double"></i> Negotiation feature</li>
                                <li><i class="fa-solid fa-check-double"></i> Contracts and orders</li>
                            </ul>
                        </div>
                    </div>
                    <span id="Approval"></span>
                    <div class="row">
                       
                        <div class="col-lg-6" style="margin-top:20px">
                            <span>Want to see how it works?</span>
                        </div>
                        <div class="col-lg-4">
                            <a routerLink="/bookademo" class="btn btn-primary" style="margin-top:10px">BOOK A DEMO</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- End Tenders -->
<!-- Start Approval procedures -->

        <div class="row align-items-center" style="margin-bottom: 30px;margin-top: 30px">
            <div class="col-lg-4 col-md-12">
                <div class="about-wrap-image">
                    <img src="assets\img\Approval2.png" style="max-width: 80%;">
                </div>
            </div>
        <div class="col-lg-6 col-md-12">
            <div class="about-wrap-content">
          <h2>Approval procedures</h2>
                <p>Be sure every operation is approved according to your policy. You can set automatic approval flow for each user or innitiate approval process manualy.</p>
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-sm-6">
                        <ul class="wrap-list">
                            <li><i class="fa-solid fa-check-double"></i> Approval of any stage of procurement</li>
                            <li><i class="fa-solid fa-check-double"></i> Approval procedure triggers based on amount</li>  
                        </ul>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <ul class="wrap-list">
                            <li><i class="fa-solid fa-check-double"></i> Individual default approval sequence for each user</li>
                         </ul>
                    </div>
                </div>
                <span id="warehouse-inventory-management"></span>
                <div class="row">
                    <div class="col-lg-6" style="margin-top:20px">
                        <span >Want to see how it works?</span>
                    </div>
                    <div class="col-lg-4">
                        <a routerLink="/bookademo" class="btn btn-primary" style="margin-top:10px">BOOK A DEMO</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- End Approval procedures -->
<!-- Start Warehouse inventory management -->
<span id="warehouse-inventory-management"></span>
        <div class="row align-items-center" style="margin-bottom: 50px; margin-top: 30px">
            <div class="col-lg-4 col-md-12">
                <div class="about-wrap-image">
                    <img src="assets\img\Warehouse2.png" style="max-width: 70%;">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-wrap-content">
                    <h2 >Warehouse inventory management</h2>
                    <p>Simple and powerful tool to manage your warehouses. Track all inventory and its availability across different locations and projects.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Unlimited number of warehouses</li>
                                <li><i class="fa-solid fa-check-double"></i> Stock movement among warehouses</li>  
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Stock write-off</li>
                                <li><i class="fa-solid fa-check-double"></i> All related documents</li>
                            </ul>
                        </div>
                    </div>
                    <span id="tools"></span>
                    <div class="row">
                       
                        <div class="col-lg-6" style="margin-top:20px">
                            <span>Want to see how it works?</span>
                        </div>
                        <div class="col-lg-4">
                            <a routerLink="/bookademo" class="btn btn-primary" style="margin-top:10px">BOOK A DEMO</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- End Warehouse inventory management -->
<!-- Start Tools and equipment management -->
<span id="tools"></span>
<div class="row align-items-center" style="margin-bottom: 30px;margin-top: 30px">
    <div class="col-lg-4 col-md-12">
        <div class="about-wrap-image">
            <img src="assets/img/Tools1.png" style="max-width: 80%;">
        </div>
    </div>
<div class="col-lg-6 col-md-12">
    <div class="about-wrap-content">
  <h2>Tools and equipment management</h2>
        <p>Tracking the movement of tools and safety equipment and monitoring their warranty or expiry date is now easier than ever.</p>
        <div class="row justify-content-center">
            <div class="col-lg-6 col-sm-6">
                <ul class="wrap-list">
                    <li><i class="fa-solid fa-check-double"></i> Automatic assignment of inventory number</li>
                    <li><i class="fa-solid fa-check-double"></i> Serial no., production and expiry date tracking</li>  
                </ul>
            </div>
            <div class="col-lg-6 col-sm-6">
                <ul class="wrap-list">
                    <li><i class="fa-solid fa-check-double"></i> Inventory and tools assignment to employees</li>
                    <li><i class="fa-solid fa-check-double"></i> Digital signature of transaction documents</li>
                 </ul>
            </div>
        </div>
        <span id="Reports"></span>
        <div class="row">
            <div class="col-lg-6" style="margin-top:20px">
                <span >Want to see how it works?</span>
            </div>
            <div class="col-lg-4">
                <a routerLink="/bookademo" class="btn btn-primary" style="margin-top:10px">BOOK A DEMO</a>
            </div>
        </div>
    </div>
</div>
</div>
<!-- End Tools and equipment management-->

<!-- Start Real-time reports and dashboards management -->
        <div class="row align-items-center" style="margin-bottom: 30px;margin-top: 30px">
            <div class="col-lg-4 col-md-12">
                <div class="about-wrap-image">
                    <img src="assets\img\Analytics1.png" style="max-width: 70%;">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-wrap-content">
                    <h2 >Real-time reports and dashboards</h2>
                    <p>Make data-based decisions with real-time information about your expenses and inventory.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Advanced data filtering by multiple criteria</li>
                                <li><i class="fa-solid fa-check-double"></i> User created data filtering templates</li>  
                                <li><i class="fa-solid fa-check-double"></i> Integration with external BI tools for enhanced analytics</li> 
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i> Real-time budget tracking for each project or product group</li>
                                <li><i class="fa-solid fa-check-double"></i> Expence tracking by project estimate</li>
                                <li><i class="fa-solid fa-check-double"></i> Budget overspend indications</li>
                            </ul>
                        </div>
                    </div>
                    <span id="purchase-catalogue"></span>
                    <div class="row">
                       
                        <div class="col-lg-6" style="margin-top:20px">
                            <span >Want to see how it works?</span>
                        </div>
                        <div class="col-lg-4">
                            <a routerLink="/bookademo" class="btn btn-primary" style="margin-top:10px">BOOK A DEMO</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
<!-- End Real-time reports and dashboards management -->


<!-- Purchase catalogue -->

<div class="row align-items-center" style="margin-bottom: 30px;margin-top: 30px">
    <div class="col-lg-4 col-md-12">
        <div class="about-wrap-image">
            <img src="assets/img/Catalogue1.png" style="max-width: 80%;">
        </div>
    </div> 

    <div class="col-lg-6 col-md-12">
        <div class="about-wrap-content">
            <h2 >Purchase catalogue</h2>
            <p>Speed up communication and order processing with a standardized catalogue. </p>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-sm-6">
                    <ul class="wrap-list">
                        <li><i class="fa-solid fa-check-double"></i> Multiple attributes</li>
                        <li><i class="fa-solid fa-check-double"></i> Multiple suppliers</li>  
                    </ul>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <ul class="wrap-list">
                        <li><i class="fa-solid fa-check-double"></i> Purchase price historry</li>
                        <li><i class="fa-solid fa-check-double"></i> All related documents</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <span id="supplier-management"></span>
                <div class="col-lg-6" style="margin-top:30px">
                    <span >Want to see how it works?</span>
                </div>
                <div class="col-lg-4">
                    <a routerLink="/bookademo" class="btn btn-primary" style="margin-top: 20px;">BOOK A DEMO</a>
                </div>
            </div>
        </div>
    </div> 
</div>


<!-- End Purchase catalogue -->
<!-- Start Supplier management -->
<div class="row align-items-center" style="margin-bottom: 30px;margin-top: 30px">
    <div class="col-lg-4 col-md-12">
        <div class="about-wrap-image">
            <img src="assets/img/Supplier1.png" style="max-width: 70%;">
        </div>
    </div>

    <div class="col-lg-6 col-md-12">
        <div class="about-wrap-content">
            <h2 >Supplier management</h2>
            <p>All information about your suppliers in one place.</p>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-sm-6">
                    <ul class="wrap-list">
                        <li><i class="fa-solid fa-check-double"></i> Supplier data and contacts</li>
                        <li><i class="fa-solid fa-check-double"></i> Supplier evaluation forms and history</li>  
                    </ul>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <ul class="wrap-list">
                        <li><i class="fa-solid fa-check-double"></i> Automaticaly calculates supplier KPI's</li>
                        <li><i class="fa-solid fa-check-double"></i> All related documents</li>
                    </ul>
                </div>
            </div>
            <span id="Reports"></span>
            <div class="row">
               
                <div class="col-lg-6" style="margin-top:35px">
                    <span>Want to see how it works?</span>
                </div>
                <div class="col-lg-4">
                    <a routerLink="/bookademo" class="btn btn-primary" style="margin-top:20px">BOOK A DEMO</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Supplier management -->
</div>

