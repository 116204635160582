import { Component, OnInit, Renderer2, ElementRef,HostListener  } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {

    location: any;
    isMobileView: boolean;

    // Listen for window resize events
    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
      this.isMobileView = window.innerWidth <= 768; // Adjust the width as needed
    }
    constructor(
        public router: Router,
        location: Location,
        private renderer: Renderer2,
        private el: ElementRef
    ) { }

    ngOnInit() {
        this.isMobileView = window.innerWidth <= 768;
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
            }
        });
    }

    collapseNavbar() {
        const navbar = this.el.nativeElement.querySelector('#navbarSupportedContent');
        if (navbar?.classList.contains('show')) {
            this.renderer.removeClass(navbar, 'show');
        }
    }
}

