import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { trigger, transition, style, animate, AnimationEvent } from '@angular/animations';

@Component({
  selector: 'app-construction',
  templateUrl: './construction.component.html',
  styleUrls: ['./construction.component.scss'],
  animations: [
    trigger('appearFromAbove', [
      transition(':enter', [
        style({ transform: 'translateZ(-10%)', opacity: 0 }),
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class ConstructionComponent implements OnInit {
  @ViewChild('generalConstruction') generalConstruction: ElementRef;
  @ViewChild('shipbuildingSection') shipbuildingSection: ElementRef;
  @ViewChild('specialtyContractors') specialtyContractors: ElementRef;

  type: string = "general";

  constructor() { }

  ngOnInit() {
    // Initially hide the footer on small screens
    if (window.innerWidth <= 768) {
      document.querySelector('footer').style.display = 'none';
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    const yOffset = window.pageYOffset;
    const scrollHeight = document.documentElement.scrollHeight;
    const viewHeight = window.innerHeight;

    // If the user has scrolled within 50px of the bottom, show the footer.
    
  }

  changetype(type: string) {
    if (this.type === type) {
        // If the same type is clicked again, just scroll to it without collapsing
        this.scrollToSection(type);
        return;
    } 

    this.type = type;
    setTimeout(() => {
        this.scrollToSection(type);
    }, 300);  // The delay ensures that the section animation completes before scrolling.
}

  animationDone(event: AnimationEvent) {
    if (event.phaseName === 'done') {
        this.scrollToSection(this.type);
    }
  }

  scrollToSection(sectionType: string) {
    switch (sectionType) {
      case 'general':
        this.generalConstruction.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'ship':
        this.shipbuildingSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'special':
        this.specialtyContractors.nativeElement.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  }
}
