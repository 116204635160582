<section class="projects-wrap-area ptb-110" style="padding-bottom:200px">
    <div class="container-fluid">
        <div class="row align-items-center ">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-card">
                    <a routerLink="/construction"><img src="assets/img/Construction.jpg" alt="image"></a>
                    <div class="projects-content">
                        <h1><a routerLink="/construction">Construction</a></h1>
                        <a routerLink="/construction" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-card">
                    <a routerLink="/manufacturing"><img src="assets/img/Manufacturing.jpg" alt="image"></a>

                    <div class="projects-content">
                        <h1><a routerLink="/manufacturing">Manufacturing</a></h1>
                        <a routerLink="/manufacturing" class="read-more-btn">Read More <i class="flaticon-add-1"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>







<section>

   