
<section class="features-style-area ptb-110" style="margin-top: 50px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12" style="margin-bottom: 30px;">
                <div class="features-style-card">
                    <h2>Budget control
                    </h2>
                    <br/>
                    <div class="col-lg-6 col-md-6 col-sm-12"></div>
                    <div class="about-wrap-content">
                        <ul class="wrap-list">
                            <li><i class="fa-solid fa-check-double"></i> Individual budgets for each project by amount or product group
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Expence tracking by project estimate
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Budget overspend indications
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
                <div class="col-lg-6 col-md-6 col-sm-12" style="margin-bottom: 30px;">
                <div class="features-style-card">
                    <h2>Data analytics
                    </h2>
                    <br/>
                    <div class="about-wrap-content">
                        <ul class="wrap-list">
                            <li><i class="fa-solid fa-check-double"></i> Advanced data filtering by multiple criteria
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> User created data filtering templates
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Integration with external BI tools for enhanced analytics
                            </li>
                           
                        </ul>
                    </div>
                </div>
                </div>
            </div>
    </div>
</section>

