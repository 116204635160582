
<section class="about-area ptb-110">
    <div class="container">
        <div class="row align-items-center">
            
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="about-content" style="margin-bottom: 30px;">
                    <h2 style="align-left">Purchase requests and orders
                    </h2>
                    <br/>
                    <div class="about-wrap-content">
                        <ul class="wrap-list">
                            <li><i class="fa-solid fa-check-double"></i> Real time statuses off purchase orders and requests
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Internal messaging and document exchange
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Full/partial delivery control
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Customizable user rights and access

                            </li>
                        </ul>
                    </div>
                </div>
                <br>
                <br>
                <div class="about-content" style="margin-bottom: 30px;">
                    <h2>Approval procedures
                    </h2>
                    <br/>
                    <div class="about-wrap-content">
                        <ul class="wrap-list">
                            <li><i class="fa-solid fa-check-double"></i> Approval of any stage of procurement 
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Approval procedure triggers based on amount
                            </li>
                            <li><i class="fa-solid fa-check-double"></i> Individual default approval sequence for each user
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <a><img src="assets/img/Orders.png" style="margin-bottom: 10px;max-height: 320px;"></a>
                <br>
                <div class="about-content">
                <h2>Tenders
                </h2>
                <br/>
                <div class="about-wrap-content" style="margin-bottom: 30px;">
                    <ul class="wrap-list">
                        <li><i class="fa-solid fa-check-double"></i> Request prices or informations 
                        </li>
                        <li><i class="fa-solid fa-check-double"></i> All bids in single, comparable table
                        </li>
                        <li><i class="fa-solid fa-check-double"></i> Messaging with suppliers and colleagues
                        </li>
                    </ul>
                </div>
                </div>
            </div> 
            </div>
        </div>
</section>

