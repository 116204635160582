<!-- Start Page Title Area -->
<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div> -->
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-110">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>We make construction and manufacturing more efficient</h2>
                    <br>
                    <p style="color: #687582; font-size: large;">At ViaCorex, we craft solutions to help your businesses thrive. Our success is built on strong partnerships; every client is a valuable collaborator in our eyes.</p>
                    <br>
                    <p style="color: #687582; font-size: large;">We're more than just another vendor. Before providing tools and services, many have known us as trusted consultants, guiding them to refine their processes.</p>
                    <br>
                    <p style="color: #687582; font-size: large;">With ViaCorex, you get more than solutions; you gain a partner committed to the future of your construction business.</p>
                    
                </div>
            </div>
        </div>

        
    </div>
</section>
<!-- End About Area -->

<!-- Start FunFacts Area -->
<section class="funfacts-area ptb-110 bg-f2feee">
    <div class="container">
        <div class="row">
            <div class="col-6 col-lg-4 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="15"></div>
                    <span>Saved</span>
                    <h3>Expences</h3>
                </div>
            </div>
    
            <div class="col-6 col-lg-4 col-md-3 col-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="50"></div>
                    <span>Increased</span>
                    <h3>Efficiency</h3>
                </div>
            </div>
    
            <div class="col-6 col-lg-4 col-md-3 col-sm-4 offset-lg-0 offset-md-0 offset-sm-4">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="100"></div>
                    <span>Unquestionable</span>
                    <h3>Transparency</h3>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FunFacts Area -->

<!-- Start Partner Area -->
<div class="partner-area">
    <div class="container">
        <div class="partner-list">
           
            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/AVS_logo_BW.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/AVS_logo_BW.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/RNDV.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/RNDV.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/Peikko_BW.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img//Peikko_BW.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank"><img src="assets/img/PST_BW.png" alt="image"></a>
                <a href="#" target="_blank"><img src="assets/img/PST_BW.png" alt="image"></a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->



